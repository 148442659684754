@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.About {
  padding: 61px 164px 107px 164px!important;
  width: 100%;
}

.aboutTitle {
  margin:  0 0 84px 0!important;
  text-align: center;
}

.aboutImg {
  width: 128px!important;
  height: 36px!important;
}

.flexDiv {
  display: flex;
  justify-content: space-between;
  width: 1112px!important;
  margin: 0 auto;
}

.leftDiv {
  width: 443px!important;
}

.rowFont {
  text-indent: 2em;
  font-size: 14px!important;
  color: #000000;
  text-align: justify;
  margin-bottom: 20px!important;
}

.rightDiv {
  width: 557px!important;
  height: 312px!important;
}

.rightImg {
  width: 557px!important;
  height: 312px!important;
}


.About {
  padding: rpx(61) rpx(164) rpx(107) rpx(164);
  width: 100%;
}

.aboutTitle {
  margin:  0 0 rpx(84) 0;
  text-align: center;
}

.aboutImg {
  width: rpx(128);
  height: rpx(36);
}

.flexDiv {
  display: flex;
  justify-content: space-between;
}

.leftDiv {
  width: rpx(443);
}

.rowFont {
  text-indent: 2em;
  font-size: rpx(14);
  color: #000000;
  text-align: justify;
  margin-bottom: rpx(30);
}

.rightDiv {
  width: rpx(557);
  height: rpx(312);
}

.rightImg {
  width: rpx(557);
  height: rpx(312);
}
