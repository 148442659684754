@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.fixedDiv {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 16px!important;
  width: 80px!important;
  height: 80px!important;
  box-shadow: 0 1px 8px 0 rgba(0, 49, 177, 0.2)!important;
  background: #ffffff;
  z-index: 10;
  border-radius: 2px!important;
}

.buttonDiv {
  position: relative;

  //&:before {
  //  content: '';
  //  position: absolute;
  //  width: 62px!important;
  //  height: 2px!important;
  //  left: 50%;
  //  top: 50%;
  //  transform: translate(-50%,-50%);
  //  background: url("./img/whiteLine.png") no-repeat center center;
  //  background-size: 62px 2px!important;
  //  z-index: 11;
  //}
}

.returnTop {
  position: relative;
  width: 80px!important;
  height: 80px!important;
  background: linear-gradient(to bottom,#F06109,#F06109);
  font-size: 20px!important;
  line-height: 27px!important;
  text-align: center;
  font-size: 20px!important;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &:hover .returnDiv {
    opacity: 0;
  }

  &:hover .bubble {
    display: block;
  }
}

.returnDiv {
  transition: 0.5s ease-in-out;
  position: absolute;
  opacity: 1;
  width: 80px!important;
  height: 80px!important;
  background: #ffffff;
  z-index: 10;
}

.returnTopIcon {
  position: absolute;
  width: 36px!important;
  height: 36px!important;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.font {
  position: absolute;
  opacity: 1;
  padding: 12px 19px 14px 20px!important;
  width: 80px!important;
  height: 80px!important;
}

.bubble {
  display: none;
  position: absolute;
  left: -310px!important;
  top: -110px!important;
  width: 315px!important;
  height: 327px!important;
  background: url("./img/bubbleBg.png")no-repeat center center;
  background-size: 315px 327px!important;
  text-align: center;
  padding-top: 30px!important;
  padding-right: 10px!important;
}

.bubbleTitle {
  font-size: 16px!important;
  color: #000000;
}

.bubbleSubTitle {
  font-size: 14px!important;
  color: rgba(0,0,0,0.6);
}

.bubbleQrcode {
  width: 200px!important;
  height: 200px!important;
}



.fixedDiv {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: rpx(16);
  width: rpx(80);
  height: rpx(80);
  box-shadow: 0 rpx(1) rpx(8) 0 rgba(0, 49, 177, 0.2);
  background: #ffffff;
  z-index: 10;
  border-radius: rpx(2);
}

.buttonDiv {
  position: relative;

  //&:before {
  //  content: '';
  //  position: absolute;
  //  width: rpx(62);
  //  height: rpx(2);
  //  left: 50%;
  //  top: 50%;
  //  transform: translate(-50%,-50%);
  //  background: url("./img/whiteLine.png") no-repeat center center;
  //  background-size: rpx(62) rpx(2);
  //  z-index: 11;
  //}
}

.returnTop {
  position: relative;
  width: rpx(80);
  height: rpx(80);
  background: linear-gradient(to bottom,#F06109,#F06109);
  font-size: rpx(20);
  line-height: rpx(27);
  text-align: center;
  font-size: rpx(20);
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &:hover .returnDiv {
    opacity: 0;
  }

  &:hover .bubble {
    display: block;
  }
}

.qrCodeDiv {
  @extend .returnTop;
}
.returnDiv {
  transition: 0.5s ease-in-out;
  position: absolute;
  opacity: 1;
  width: rpx(80);
  height: rpx(80);
  background: #ffffff;
  z-index: 10;
}

.returnTopIcon {
  position: absolute;
  width: rpx(36);
  height: rpx(36);
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.qrCodeIcon {
  @extend .returnTopIcon;
}

.font {
  position: absolute;
  opacity: 1;
  padding: rpx(12) rpx(19) rpx(14) rpx(20);
  width: rpx(80);
  height: rpx(80);
}

.bubble {
  display: none;
  position: absolute;
  left: rpx(-310);
  top: rpx(-110);
  width: rpx(315);
  height: rpx(327);
  background: url("./img/bubbleBg.png")no-repeat center center;
  background-size: rpx(315) rpx(327);
  text-align: center;
  padding-top: rpx(30);
  padding-right: rpx(10);
}

.bubbleTitle {
  font-size: rpx(16);
  color: #000000;
}

.bubbleSubTitle {
  font-size: rpx(14);
  color: rgba(0,0,0,0.6);
}

.bubbleQrcode {
  width: rpx(200);
  height: rpx(200);
}
