@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.ProductGoodness {
  padding: 62px 164px 0 164px !important;
  width: 100%;
  height: 750px !important;
  background: url("./img/bg.png") no-repeat center center;
  background-size: cover;
}

.productTitle {
  margin: 0 0 54px 0 !important;
  text-align: center;
}

.productGoodness {
  width: 442px !important;
  height: 36px !important;
}

.productCard {
  display: flex;
  flex-wrap: wrap;
  width: 1112px !important;
  margin: 0 auto;
}

.cardDiv {
  width: 350px !important;
  height: 250px !important;
  border-radius: 4px !important;
  text-align: center;
  padding: 29px 35px 0 35px !important;
  margin-bottom: 32px !important;
  background: #ffffff;

  &:hover {
    background: #E7EDFE;
  }
}

.cardIcon {
  width: 68px !important;
  height: 68px !important;
  margin-bottom: 7px !important;
}

.cardTitle {
  font-size: 18px !important;
  font-weight: 600;
  color: #000000;
  margin-bottom: 7px !important;
}

.cardDetail {
  font-size: 12px !important;
  line-height: 18px !important;
  color: #98A4B0;
}


.ProductGoodness {
  padding: rpx(62) rpx(164) 0 rpx(164);
  width: 100%;
  height: rpx(800);
  background: url("./img/bg.png") no-repeat center center;
  background-size: cover;
}

.productTitle {
  margin: 0 0 rpx(54) 0;
  text-align: center;
}

.productGoodness {
  width: rpx(442);
  height: rpx(36);
}

.productCard {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.cardDiv {
  width: 30%;
  height: rpx(210);
  border-radius: rpx(4);
  text-align: center;
  padding: rpx(29) rpx(35) 0 rpx(35);
  margin-bottom: rpx(32);
  background: #ffffff;

  &:hover {
    background: #E7EDFE;
  }
}

.cardIcon {
  width: rpx(68);
  height: rpx(68);
  margin-bottom: rpx(7);
}

.cardTitle {
  font-size: rpx(18);
  font-weight: 600;
  color: #000000;
  margin-bottom: rpx(7);
}

.cardDetail {
  font-size: rpx(12);
  line-height: rpx(18);
  color: #98A4B0;
}
