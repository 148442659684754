@import "src/Components/SCSS/mixins";

.root{
 background: #ffffff;
}

//---------------pc头部start

.topDiv {
  position: relative;
}

.greenBg {
  width: 100%;
  background: #EF7F08;
  text-align: center;
}

.blueBg {
  position: absolute;
  top: 0;
  left: 0;
  animation: move2 15s infinite;//渐显渐隐效果效果添加
  width: 100%;
  text-align: center;
  background: #DB4C43;
}

.topBg {
}

.imgDiv {
  margin: 0 auto;
  position: relative;
  //width: rpx(1440);
  //max-width: 1440px;
}

.topBg2 {
}

//渐显渐隐效果循环
@keyframes move2 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.freeButton {
  width: rpx(166);
  max-width: 166px;
  max-height: 46px;
  height: rpx(46);
  position: absolute;
  z-index: 10;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.freeButton {
  top: rpx(320);
  left: rpx(180);
}

.topBg {
  width: 100%;
  height: rpx(520);
}

.topBg2 {
  width: 100%;
  height: rpx(520);
}

.freeButton {
  top: 320px!important;

  left: 50%;
  transform: translateX(-545px);
  &:hover{
    transform: translateX(-545px) scale(1.1);
  }
}

.topBg {
  width: 1440px;
  height: 520px;
}

.topBg2 {
  width: 1440px;
  height: 520px;
}

.imgDiv {
  width: 1440px;
  height: 520px;
}


.fixedHeader {
  position: fixed!important;
  left: 0!important;
  top: 0!important;
  background: #ffffff!important;
  box-shadow: rpx(0) rpx(10) rpx(12) 0 rgba(153,153,153,0.1);
}


.text1{
  position: absolute;
  left: 250px;
  top: 170px;
  white-space: pre-wrap;
  font-size: 49px;
  color: #fff;
  text-align: left;
}

.text2{
  position: absolute;
  left: 160px;
  top: 170px;
  white-space: pre-wrap;
  font-size: 49px;
  color: #fff;
  text-align: center;
}

.text3{
  font-size: 24px;
}

.qrcode{
  position: absolute;
  left: 50%;
  top: 300px;
  width: 120px;
  height: 120px;

  img{
    width: 100%;
    height: 100%;
  }
}
