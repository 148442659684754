@use 'sass:math';
@use 'sass:color';
@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";
@import "src/Components/SCSS/_functions";

* {
  box-sizing: border-box;
}

//math.div是除的意思
html{
  font-size: 1vw;
}

//html{
//  font-size: 1vw;
//
//  @include responsive(3) {
//    font-size: #{(1440 / 375)}vw;
//  }
//}

body {
  min-width: 1440px;
  margin: 0;
  padding: 0;
  background-color: $body-bg;
  font-family: 'Open Sans',
  sans-serif,
  'PingFangSC';
  scrollbar-gutter: stable both-edges;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: $primary-color;
  }
}

.container {
  box-sizing: border-box;
  width: rpx(1200);
  margin: 0 auto;
}

.flex {
  display: flex;
}

.noscroll {
  overflow: hidden;
}

.swiper-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slick-vertical .slick-slide {
  border: 0 !important;
}
