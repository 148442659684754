@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.headerDiv{
  z-index: 100;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  width: rpx(1081);
  max-width: 1081px;
  margin: 0 auto;
  height: rpx(64);
  max-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: rpx(142);
  max-width: 142px;
  max-height: 48px;
  cursor: pointer;
}

.navDiv {
  display: flex;
  align-items: center;
}

.navButton {
  font-size: 15px!important;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  padding: 0 25px;
  transition: all 0.2s ease-in;
  &:hover {
    color: rgba(255,255,255,0.8);
  }
}

.freeButton {
  margin-left: 96px!important;
  color: #ffffff;
  width: 90px!important;
  height: 31px!important;
  line-height: 29px!important;
  border-radius: 3px!important;
  border: 1px solid #ffffff!important;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease-in;
  font-size: 14px!important;
  &:hover {
    color: rgba(255,255,255,0.8);
    border: 1px solid rgba(255,255,255,0.8);
  }
}

.navButton {
  font-size: rpx(15);
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  padding: 0 rpx(25);
  transition: all 0.2s ease-in;
  &:hover {
    color: rgba(255,255,255,0.8);
  }
}

.fixedNavButton {
  @extend .navButton;
  color: #000000;
  &:hover {
    color: rgba(0,0,0,0.8);
  }
}

.freeButton {
  margin-left: rpx(96);
  color: #ffffff;
  width: rpx(90);
  height: rpx(31);
  line-height: rpx(29);
  border-radius: rpx(3);
  border: 1px solid #ffffff;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease-in;
  font-size: rpx(14);
  &:hover {
    color: rgba(255,255,255,0.8);
    border: 1px solid rgba(255,255,255,0.8);
  }
}

.fixedFreeButton {
  @extend .freeButton;
  line-height: rpx(31);
  border: 0;
  background: #4D71F9;

  &:hover {
    color: rgba(255,255,255,0.8);
    border: 0;
  }
}

