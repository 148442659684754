@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.solution {
  margin: 0 auto;
  width: 1212px !important;
  min-width: 950px;
}

.solTitle {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px !important;
}

.solutionTitle {
  margin: 0 auto;
  width: 185px !important;
  height: 36px !important;
}

.tabDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 91px 60px !important;
}

.tabLeft {
  width: 460px !important;
}

.leftTabTitle {
  font-size: 39px !important;
  color: #000000;
  font-weight: 600;
  margin-bottom: 22px !important;
}

.leftTabDetail {
  font-size: 14px !important;
  line-height: 27px !important;
  text-align: justify;
}

.tabRight {
  position: relative;
  width: 610px !important;
  height: 400px !important;
  line-height: 400px !important;
  text-align: center;
  background: url("./img/bluePointBg.png") no-repeat center center;
  background-size: cover;
}

.yunyingDiv {
  position: relative;
  width: 473px !important;
  height: 354px !important;
  margin: 0 auto;
}

.moreAct {
  position: absolute;
  left: -80px !important;
  top: 130px !important;
  width: 169px !important;
  height: 80px !important;
}


.checkAct:hover .qrCode {
  opacity: 1;
}

.checkMoreVideo:hover .qrCode2 {
  opacity: 1;
}

.qrCode {
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  width: 250px !important;
  height: 258px !important;
  bottom: 35px !important;
  left: -34px !important;
  z-index: 5;
}

.qrCode2 {
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  width: 257px !important;
  height: 263px !important;
  bottom: 35px !important;
  left: -40px !important;
  z-index: 5;
}


.showImg {
  width: 100%;
}

.showImg1 {
  width: 557px !important;
}

.showImg2 {
  width: 557px !important;
}

.showImg3 {
  width: 557px !important;
}

.showImg4 {
  width: 557px !important;
}

.showImg5 {
  width: 557px !important;
}

.showImg6 {
  width: 557px !important;
}

.showImg7 {
  width: 557px !important;
}

.showImg8 {
  width: 610px !important;
  height: 320px !important;
}


.solution {
  margin: 0 auto;
  width: rpx(1212);
  min-width: 950px;
}

.solTitle {
  text-align: center;
  margin-bottom: rpx(40);
}

.solutionTitle {
  margin: 0 auto;
  width: rpx(185);
  height: rpx(36);
}

.tabDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 rpx(91) rpx(60);
}

.tabLeft {
  width: rpx(460);
}

.leftTabTitle {
  font-size: rpx(39);
  color: #000000;
  font-weight: 600;
  margin-bottom: rpx(22);
}

.leftTabDetail {
  font-size: rpx(14);
  line-height: rpx(27);
  text-align: justify;
}

.tabRight {
  position: relative;
  width: rpx(610);
  height: rpx(400);
  line-height: rpx(400);
  text-align: center;
  background: url("./img/bluePointBg.png") no-repeat center center;
  background-size: cover;
}

.yunyingDiv {
  position: relative;
  width: rpx(473);
  height: rpx(354);
  margin: 0 auto;
}

.moreAct {
  position: absolute;
  left: rpx(-80);
  top: rpx(130);
  width: rpx(169);
  height: rpx(80);
}

.checkAct:hover .qrCode {
  opacity: 1;
}

.checkMoreVideo:hover .qrCode2 {
  opacity: 1;
}

.qrCode {
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  width: rpx(250);
  height: rpx(258);
  bottom: rpx(35);
  left: rpx(-34);
  z-index: 5;
}

.qrCode2 {
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  width: rpx(257);
  height: rpx(263);
  bottom: rpx(35);
  left: rpx(-40);
  z-index: 5;
}


.showImg {
  width: 100%;
}

.showImg1 {
  width: rpx(499);
}

.showImg2 {
  width: rpx(496);
  height: rpx(354);
}

.showImg3 {
  width: rpx(486);
  height: rpx(369);
}

.showImg4 {
  width: rpx(541);
  height: rpx(354);
}

.showImg5 {
  width: rpx(473);
  height: rpx(354);
}

.showImg6 {
  width: rpx(493);
  height: rpx(362);
}

.showImg7 {
  width: rpx(508);
  height: rpx(383);
}

.showImg8 {
  width: rpx(610);
  height: rpx(320);
}
